import React, { useState, useEffect } from 'react'
import { Box, Input, InputGroup, InputLeftAddon, Icon, Select, Text, Flex } from '@chakra-ui/react'
import { FormattedMessage } from 'react-intl'
import { useIntl } from 'react-intl'
import { BiCheck } from 'react-icons/bi'

const IntPhoneInput = (props) => {
  // const [value, setValue] = useState(props.value ? props.value.substr(7,12) : '+33')
  const [value, setValue] = useState('')
  const [intlNumber, setIntlNumber] = useState(null)
  const [indicatif, setIndicatif] = useState('+33')
  const [isValid, setIsValid] = useState(false)

  if (props.value !== null) {
    // setIndicatif( props.value.substr(0,3));
    // setValue( `0${props.value.substr(4,12)}`)
  }

  useEffect(() => {
    if (props.value) {
      setValue(`0${props.value.substr(8, 15)}`)
      setIndicatif(props.value.substr(0, 3))
    }
  }, [props.value]);

  useEffect(() => {
    if (value) {
      formatPhoneNumber(value, false)
    }
  }, [indicatif]);

  let formatPhoneNumber = (str, updateComponent = true) => {
    var value
    if (typeof str === null) { return }
    let cleaned = str.replace(/ /g, "");
    if (cleaned.length === 10) {
      setIsValid(true)
    } else if (cleaned.length > 10) {
      cleaned = cleaned.substr(0, 10)
    } else {
      cleaned = cleaned
    }

    // Handle 0 missing
    let zeroMissingMatch = cleaned.match(/^([1-9]{1})(\d)+$/);
    if (zeroMissingMatch) {
      value = '0' + cleaned
    } else {
      value = cleaned
    }

    let validNumberMatch = cleaned.match(/^([0-0]{1})(\d{8,9})$/);
    if (validNumberMatch) {
      setIsValid(true)
      let intlCode = indicatif
      let intNumber = [intlCode, ' (', validNumberMatch[1], ') ', validNumberMatch[2]].join('')
      setIntlNumber(intNumber)
      if (updateComponent) {
        props.updatePhoneNumber(intNumber)
      }
    } else {
      setIsValid(false)
    }
    return value;
  }
  const intl = useIntl();
  return (
    <Box>
      <InputGroup>
        <Select
          onChange={(event) => {
            setIndicatif(event.target.value)
            formatPhoneNumber(value)
          }}
          value={indicatif}
          maxW='110px'
          fontSize='13px'
          borderTopRightRadius='0' borderBottomRightRadius='0'
        >
          {process.env.GATSBY_LANG === 'fr' ?
            <>
              <option value="+30">Grèce</option>
              <option value="+31">Pays-Bas</option>
              <option value="+32">Belgique</option>
              <option value="+33">France</option>
              <option value="+34">Espagne</option>
              <option value="+39">Italie</option>
              <option value="+41">Suisse</option>
              <option value="+43">Autriche</option>
              <option value="+44">Royaume-Uni</option>
              <option value="+45">Danemark</option>
              <option value="+46">Suède</option>
              <option value="+47">Norvège</option>
              <option value="+48">Pologne</option>
              <option value="+49">Allemagne</option>
            </>
            :
            <>
              <option value="+30">Greece</option>
              <option value="+31">Netherlands</option>
              <option value="+32">Belgium</option>
              <option value="+33">France</option>
              <option value="+34">Spain</option>
              <option value="+39">Italy</option>
              <option value="+41">Switzerland</option>
              <option value="+43">Austria</option>
              <option value="+44">Great-Britain</option>
              <option value="+45">Denmark</option>
              <option value="+46">Sweden</option>
              <option value="+47">Norway</option>
              <option value="+48">Poland</option>
              <option value="+49">Germany</option>
            </>
          }
        </Select>
        <InputLeftAddon borderTopLeftRadius='0' borderBottomLeftRadius='0' children={indicatif} />
        <Input
          type="tel"
          roundedLeft="0"
          placeholder={intl.messages['form.international.placeholder']}

          value={value}
          onChange={(event) => {
            setValue(formatPhoneNumber(event.target.value))
          }}

        />
      </InputGroup>
      {isValid ?
        <Flex
          justify={'flex-end'}
        >
          <Text color='green.400' textAlign="right" fontSize="14px">
            <FormattedMessage id='form.international.number' />
            : {intlNumber}</Text>
        </Flex>
        : null
      }


    </Box>
  )
}

export default IntPhoneInput