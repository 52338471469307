import axios from "axios";
import { navigate } from "gatsby";

const API_URL = "http://localhost:8080/api/auth/";

class AuthService {
  login(token, redirect) {
    return axios
      .post(`${process.env.GATSBY_API_URL}/user/login/${token}`)
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem("wbAuth", JSON.stringify(response.data));
          // this.synchronizeFavorites( response.data.userId)
        }
        navigate(redirect ? redirect : '/')

        return response.data;
      });
  }

  sendAuthLink(email, redirect) {
    return axios
      .post(`${process.env.GATSBY_API_URL}/user/send-auth-link/`, { 'email': email, 'redirect': redirect })
      .then(response => {
        // if (response.data) {
        //   localStorage.setItem("wbAuth", JSON.stringify(response.data));
        // }

        return response.data;
      });
  }

  signup(email, name, locale) {
    return axios
      .post(`${process.env.GATSBY_API_URL}/user/`,
        {
          email: email,
          name: name,
          locale: locale
        })
      .then(response => {
        // if (response.data) {
        //   localStorage.setItem("wbAuth", JSON.stringify(response.data));
        // }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("wbAuth");
    localStorage.removeItem("wbUser");
  }

  register(username, email, password) {
    return axios.post(API_URL + "signup", {
      username,
      email,
      password
    });
  }

  getCurrentUser() {
    if (typeof window !== 'undefined') {
      return JSON.parse(localStorage.getItem('wbAuth'));
    }
    else {
      return null
    }
  }

  // synchronizeFavorites(userId) {
  //   console.log('synchronize favorite', userId)
  //   axios.get(`${process.env.GATSBY_API_URL}/favorite/user/${userId}`)
  //     .then((response) => {
  //       localStorage.setItem("WbFavoritesAds", JSON.stringify(response.data))
  //     })
  // }

}

export default new AuthService();